<div fxLayout="column" fxLayoutAlign="center">
  <span *ngIf="company.trialStatus && company.remainingTrialDays == 0" fxLayoutAlign="center center" class="status">
    Company <span *ngIf="company.remainingTrialDays > 0"> {{ company.remainingTrialDays }}-Days </span> free trial
    account has
    expired <span *ngIf="user.userType == 'Admin'"> &nbsp; and you can no longer create and
      manage campaigns. &nbsp; <a routerLink="account/license-management" class="btn btn-xs btn-info "> BUY LICENSES
        NOW </a> &nbsp; to enjoy all the features of YouAttest.</span>
  </span>
  <span *ngIf="user.keyExpire" fxLayoutAlign="center center" class="status">
    Your cloud provider API token has expired <span *ngIf="user.userType == 'Admin'"> &nbsp; and you can no longer
      create and manage
      campaigns.</span>
  </span>
  <span *ngIf="company.trialStatus && company.remainingTrialDays > 0" fxLayoutAlign="center center" class="bg-yellow">
    {{company.remainingTrialDays}} Days left in your free trial.&nbsp;<span *ngIf="user.userType == 'Admin'"> &nbsp; <a
        routerLink="account/license-management" class="btn btn-xs btn-info "> "BUY LICENSES
        NOW" </a> &nbsp; and enjoy uninterrupted access to your YouAttest Tenant. </span></span>
</div>

<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">
    <fury-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></fury-toolbar-sidenav-mobile-toggle>

    <div [fxShow.gt-sm]="topNavigation$ | async" [fxShow.xs]="false" [routerLink]="['/']" class="toolbar-logo" fxShow>
      <div class="fill-primary-contrast">
        <img src="../../../assets/images/atlogo.png" class="dashboardlogo" alt="">
      </div>
      <!-- <svg viewBox="0 0 138.16 70.27" xmlns="http://www.w3.org/2000/svg">
        <title>Logo</title>
        <polygon
          points="0 54.1 11.05 54.1 11.03 32 28.82 32 30.83 23.22 11.07 23.22 11.03 8.8 34.48 8.8 36.85 0 0 0 0 54.1">
        </polygon>
        <path
          d="M44.34,34.92a8.78,8.78,0,0,0,.72,3.53A9.28,9.28,0,0,0,47,41.33a8.71,8.71,0,0,0,2.91,1.91,9.56,9.56,0,0,0,3.57.68,8.79,8.79,0,0,0,6.33-2.59,9.28,9.28,0,0,0,2-2.88,9,9,0,0,0,.72-3.53V15.77h10V34.92A19,19,0,0,1,71,42.37a19.43,19.43,0,0,1-4,6.09,18.7,18.7,0,0,1-6,4.06A18.37,18.37,0,0,1,53.63,54a19,19,0,0,1-7.52-1.48A19.36,19.36,0,0,1,40,48.46a19.05,19.05,0,0,1-4.14-6.05,18.47,18.47,0,0,1-1.52-7.49V15.77h10Z">
        </path>
        <path
          d="M93.81,25.85a8.74,8.74,0,0,0-3.53.72,9.19,9.19,0,0,0-2.88,1.94,8.68,8.68,0,0,0-1.91,2.88,8.22,8.22,0,0,0-.61,3.53V54H74.65V34.92A19.1,19.1,0,0,1,80.2,21.38a19.57,19.57,0,0,1,6.08-4.1,18.51,18.51,0,0,1,7.45-1.51H96V25.85Z">
        </path>
        <path
          d="M138.16,15.77v36a18.35,18.35,0,0,1-1.59,7.2,19.2,19.2,0,0,1-4.07,5.9,18.8,18.8,0,0,1-13.28,5.4V60.34a8.34,8.34,0,0,0,3.38-.69,8.84,8.84,0,0,0,2.78-1.87,9.41,9.41,0,0,0,1.9-2.77,9.06,9.06,0,0,0,.8-3.39,16.87,16.87,0,0,1-4.25,1.77,18.11,18.11,0,0,1-4.68.61,18.48,18.48,0,0,1-7.45-1.51,19.84,19.84,0,0,1-6.12-4.11,19.51,19.51,0,0,1-4.18-6,18,18,0,0,1-1.55-7.42V15.77h10V34.92a9.37,9.37,0,0,0,2.74,6.34,9,9,0,0,0,9.86,1.87,9.25,9.25,0,0,0,4.82-4.79,8.33,8.33,0,0,0,.72-3.42V15.77Z">
        </path>
      </svg> -->
    </div>
    <fury-toolbar-search-bar fxFlex="500px" fxHide fxShow.gt-md></fury-toolbar-search-bar>
  </div>
  <div fxLayout="row">

    <fury-toolbar-fullscreen-toggle class="toolbar-button"></fury-toolbar-fullscreen-toggle>

    <fury-toolbar-search fxHide fxShow.gt-sm fxHide.gt-md class="toolbar-button"></fury-toolbar-search>

    <fury-toolbar-notifications class="toolbar-button"></fury-toolbar-notifications>
    
    <fury-toolbar-info class="toolbar-button"></fury-toolbar-info>

    <fury-toolbar-user class="toolbar-button"></fury-toolbar-user>

    <!-- <fury-toolbar-quickpanel-toggle (openQuickPanel)="openQuickPanel.emit()" class="toolbar-button">
    </fury-toolbar-quickpanel-toggle> -->
  </div>
</div>