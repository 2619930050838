import { email, minLength, required } from '@rxweb/reactive-form-validators';

export class User {

  id: number;
  index: number;
  name: string;
  @required()
  firstName: string;
  @required()
  lastName: string;
  @required()
  @email()
  email: string;
  @required()
  @minLength({ value: 6 })
  password: string;
  companyId: number;
  accessId: number;
  @required()
  userType: string;
  ssoStatus: number;
  status: string;
  cloudProvider: string;
  accessToken: string;
  refreshToken: string;
  isJip: number;
  jipStatus: boolean;
  keyExpire: boolean;
  cloudIntegrationEnable: boolean;
  factorId: number;
  factorPhone: string;
  factorType: string;
  factorStatus: string = 'Active';
  uuid: string;
  userId: number;
  userStatus: number;
  trialStatus: number;
  trialDays: number;
  accessRequestEnable: boolean;
  secretKey: string;
}